<template>
  <b-row>
    <b-col sm="12" md="6">

      <dl class="row">
        <dt class="col-sm-3">Regional</dt>
        <dt class="col-sm-9">{{data.regional_name}}</dt>
      </dl>
      <dl class="row">
        <dt class="col-sm-3">Cabang</dt>
        <dt class="col-sm-9">{{branchList}}</dt>
      </dl>
      <dl class="row">
        <dt class="col-sm-3">Username</dt>
        <dt class="col-sm-9">{{data.username}}</dt>
      </dl>
      <dl class="row">
        <dt class="col-sm-3">Nama Lengkap</dt>
        <dt class="col-sm-9">{{data.name}}</dt>
      </dl>
      <dl class="row">
        <dt class="col-sm-3">Email</dt>
        <dt class="col-sm-9">{{data.email}}</dt>
      </dl>
      <dl class="row">
        <dt class="col-sm-3">Keterangan</dt>
        <dt class="col-sm-9">{{data.note}}</dt>
      </dl>
      <dl class="row">
        <dt class="col-sm-3">Telepon</dt>
        <dt class="col-sm-9">{{data.tlp}}</dt>
      </dl>
      <dl class="row">
        <dt class="col-sm-3">Jabatan</dt>
        <dt class="col-sm-9">{{data.group_name}}</dt>
      </dl>
      <dl class="row">
        <dt class="col-sm-3">Last Login</dt>
        <dt class="col-sm-9">{{data.last_login | fulldate}}</dt>
      </dl>

    </b-col>
  </b-row>
</template>

<script>
import { mapActions } from 'vuex'
import _ from 'lodash'

export default {
  data(){
    return {
      data:{},
      branchList:''
    }
  },
  methods:{
    ...mapActions({
      dispatchShow: 'users/show'
    }),
    async getData(){
      try {
        const id = this.$route.params.id
        const data = await this.dispatchShow(id)
        this.data = data
        if(data.user_branches) this.branchList = _.join(_.map(data.user_branches,'branch_name'),'; ')
      } catch (error) {
        this.handleError(error)
      }
    }
  },
  mounted(){
    this.getData()
  }
}
</script>

<style>

</style>